import {useLogger, useSpanWrap} from '#imports';
import type {Client} from '@prismicio/client';
import type {ResourcesCenterHeaderDocument} from '@prismicio-types';
/**
 * Retrieve document from Prismic repository.
 * @param documentType  Document type.
 * @param uid           Document UID.
 */

export default async function usePrismicHeader(client: Client, locale: string) {
    const logger = useLogger();

    const lang = locale?.replace('_', '-') ?? 'fr-FR';

    try {
        const getPrismicDocument = () => client!.getSingle<ResourcesCenterHeaderDocument>('resourcesCenterHeader', {lang});
        return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: '[prismic] resourcesCenterHeader'});
    } catch (err) {
        logger.info(err);
        logger.info(`Could not retrieve document from Prismic with parameters: [resourcesCenterHeader, ${lang}]`);
    }

    // if the document does not exist, check for redirect or throw 404
}
