<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
    import {ref, provide, watch, onBeforeMount, onBeforeUnmount} from 'vue';
    import type {PrismicDocument} from '@prismicio/types';
    import {USER_LOCALE, MEDIA_BREAKPOINTS, PRISMIC_DOCUMENT, PRISMIC_CLIENT, RC_NAVBAR, HOST_SETTINGS} from '~/app/keys';
    import {useBreakpointsHelper} from '~/composables/useBreakpointsHelpers';
    import usePrismicHeader from '~/composables/content/usePrismicHeader';
    import type {HostSettingsResource} from '#imports';
    import {
        usePrismicClient,
        useLocale,
        useDocumentMetadata,
        usePrismicPreviewScript,
        useAsyncData,
        useHostSettings,
        useScripts,
        useHead,
    } from '#imports';
    import type {ResourcesCenterHeaderDocument} from '@malt/nuxt-prismic-module/prismicio-types';

    usePrismicPreviewScript();

    const {locale} = useLocale();
    provide(USER_LOCALE, locale.value);

    const breakpoints = ref(useBreakpointsHelper());
    provide(MEDIA_BREAKPOINTS, breakpoints);

    const document = ref<PrismicDocument>();
    provide(PRISMIC_DOCUMENT, document);

    const client = usePrismicClient();
    provide(PRISMIC_CLIENT, client);

    const {data: navbarDocument} = await useAsyncData('navbar', () => usePrismicHeader(client, locale.value));
    provide(RC_NAVBAR, navbarDocument.value as ResourcesCenterHeaderDocument);

    function handleResize() {
        breakpoints.value = useBreakpointsHelper();
    }
    const {data: hostSettings} = await useAsyncData('host-settings', () => useHostSettings());
    provide(HOST_SETTINGS, hostSettings.value as HostSettingsResource);

    const script = useScripts(hostSettings.value);

    useHead({script});
    watch([document], () => {
        try {
            if (window) {
                window.dispatchEvent(new CustomEvent('Navigation'));
            }
        } catch (err) {}
        if (document.value) {
            // TO DO : add useHeadMetadata
            useDocumentMetadata(document.value);
        }
    });

    onBeforeMount(() => {
        window.addEventListener('resize', handleResize);
    });
    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
    });
</script>
