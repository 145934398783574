import {useHead, useRequestHeaders, useTranslation, OpenGraphMetaTag, useSeoMeta, useOpenGraphMetaTags} from '#imports';
import {isFilled} from '@prismicio/helpers';
import type {PrismicDocument} from '@prismicio/types';
import linkResolver from '~/app/prismic/link-resolver';

export function useDocumentMetadata(document: PrismicDocument) {
    const {t} = useTranslation();
    const openGraphMeta = new OpenGraphMetaTag({
        title: document.data.openGraphTitle ?? document.data.metaTitle ?? undefined,
        description: document.data.openGraphDescription ?? document.data.metaDescription ?? undefined,
        image: isFilled.image(document.data.openGraphImage) ? document.data.openGraphImage.url : undefined,
        t,
    });
    useSeoMeta({
        title: document.data.metaTitle ?? openGraphMeta.title,
        description: document.data.metaDescription ?? openGraphMeta.description,
    });
    useOpenGraphMetaTags(openGraphMeta);

    const baseUrl = import.meta.server ? `https://${useRequestHeaders().host}` : window.location.origin;

    useHead({
        link: [
            {
                rel: 'canonical',
                href: baseUrl + linkResolver(document).replace(/\/$/, ''),
            },
        ],
    });
    if (document.data?.noIndex) {
        useHead({
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex',
                },
            ],
        });
    }
}
