import type {Route} from '@prismicio/client';

interface Doc {
    lang: string;
    type: string;
    uid?: string | null;
}

export const CMS_PREFIX = 'c';
export const RC_PREFIX = 'resources';

/**
 * Routes should be expressed as an array to be passed to the default Prismic client.
 */
export const routes: Route[] = [
    {
        type: 'custom-page',
        path: `/${CMS_PREFIX}/:uid`,
    },
    {
        type: 'homepage',
        path: '/',
    },
    {
        type: 'companies-parent-page',
        path: `/${CMS_PREFIX}/companies`,
    },
    {
        type: 'companies-child-page',
        path: `/${CMS_PREFIX}/companies/:uid`,
    },
    {
        type: 'solutions-parent-page',
        path: `/${CMS_PREFIX}/solutions`,
    },
    {
        type: 'solutions-child-page',
        path: `/${CMS_PREFIX}/solutions/:uid`,
    },
    {
        type: 'freelancers-parent-page',
        path: `/${CMS_PREFIX}/freelancers`,
    },
    {
        type: 'freelancers-child-page',
        path: `/${CMS_PREFIX}/freelancers/:uid`,
    },
    {
        type: 'resources-parent-page',
        path: `/${CMS_PREFIX}/resources`,
    },
    {
        type: 'resources-child-page',
        path: `/${CMS_PREFIX}/resources/:uid`,
    },
    {
        type: 'resourcesCenterHome',
        path: `/${RC_PREFIX}`,
    },
    {type: 'homeArticles', path: `/${RC_PREFIX}/articles`},
    {
        type: 'article',
        path: `/${RC_PREFIX}/article/:uid`,
    },
    {type: 'homeEvents', path: `/${RC_PREFIX}/events`},
    {type: 'homeSuccessStories', path: `/${RC_PREFIX}/success-stories`},
    {
        type: 'successStory',
        path: `/${RC_PREFIX}/success-story/:uid`,
    },
    {type: 'homeGuides', path: `/${RC_PREFIX}/guides`},
    {
        type: 'guide',
        path: `/${RC_PREFIX}/guide/:uid`,
    },
    {
        type: 'collection',
        path: `/${RC_PREFIX}/collection/:uid`,
    },
    {
        type: 'homeCollections',
        path: `/${RC_PREFIX}/collections`,
    },
];

function linkResolver(doc: Doc): string {
    const path = routes.find((route) => route.type === doc.type)?.path?.replace(':uid', doc.uid || '');
    return path ?? `/${RC_PREFIX}`;
}

export default linkResolver;
