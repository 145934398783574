import type {InjectionKey, Ref} from 'vue';
import type {Client} from '@prismicio/client';
import type {PrismicDocument} from '@prismicio/types';
import type {useHostSettings, Breakpoints} from '#imports';
// import type {Breakpoints} from '~/composables/useBreakpointsHelpers';
import type {ResourcesCenterHeaderDocument} from '@prismicio-types';

export const PRISMIC_CLIENT: InjectionKey<Client> = Symbol('Prismic Client');
export const PRISMIC_DOCUMENT: InjectionKey<Ref<PrismicDocument | undefined | null>> = Symbol('Prismic Document');
export const PRISMIC_HEADER_DOCUMENT: InjectionKey<PrismicDocument> = Symbol('Prismic Header Document');
export const USER_LOCALE: InjectionKey<string> = Symbol('User locale');
export const HOST_SETTINGS: InjectionKey<ReturnType<typeof useHostSettings> extends Promise<infer R> ? R : never> = Symbol('Current host setting');
export const TRANSLATE_URL: InjectionKey<(lang: string, country: string) => Promise<string>> = Symbol('Translate Url');
export const MEDIA_BREAKPOINTS: InjectionKey<Ref<Breakpoints>> = Symbol('Media breakpoints for CSS');
export const CATEGORIES = [
    'techAndProduct',
    'data',
    'marketingAndCommunication',
    'designAndCreation',
    'businessConsultingAndStrategy',
    'businessFunctions',
    'industrialEngineering',
] as const;

export const AUDIENCES = ['all', 'freelancers', 'companies'] as const;
export const RC_NAVBAR: InjectionKey<ResourcesCenterHeaderDocument> = Symbol('RC Navbar');

export const FETCH_LINKS = [
    'article.title',
    'article.thumbnail',
    'article.excerpt',
    'article.duration',
    'article.releaseDate',
    'article.category',
    'article.audience',
    'collection.title',
    'collection.slices',
    'collection.excerpt',
    'event.title',
    'event.category',
    'event.audience',
    'event.date',
    'event.thumbnail',
    'event.signupLink',
    'event.replayLink',
    'guide.title',
    'guide.thumbnail',
    'guide.excerpt',
    'guide.releaseDate',
    'guide.category',
    'guide.audience',
    'successStory.title',
    'successStory.thumbnail',
    'successStory.excerpt',
    'successStory.releaseDate',
    'successStory.category',
    'successStory.audience',
];
