
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91uid_93630VM4JV9AMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/article/[uid].vue?macro=true";
import { default as _91_91page_93_93LdES4Bg3zuMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/articles/[[page]].vue?macro=true";
import { default as _91uid_93Wn9mLTdU90Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/collection/[uid].vue?macro=true";
import { default as _91_91page_93_93S0ZkHmzYpZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/collections/[[page]].vue?macro=true";
import { default as _91_91page_93_936fmv2j4utKMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/events/[[page]].vue?macro=true";
import { default as _91uid_93zCxOAr7IMUMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/guide/[uid].vue?macro=true";
import { default as _91_91page_93_93wk5vKDDDZoMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/guides/[[page]].vue?macro=true";
import { default as indexXYvYUT5uHWMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/index.vue?macro=true";
import { default as searchJQUD15nmFhMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/search.vue?macro=true";
import { default as _91_91page_93_93gsLOHjypcsMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/success-stories/[[page]].vue?macro=true";
import { default as _91uid_930nQxhdMZs7Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/success-story/[uid].vue?macro=true";
import { default as previewRWt8dgikhZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/preview.vue?macro=true";
import { default as redirect5KjWUcJ6qNMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/redirect.vue?macro=true";
export default [
  {
    name: "resources-article-uid",
    path: "/resources/article/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/article/[uid].vue")
  },
  {
    name: "resources-articles-page",
    path: "/resources/articles/:page?",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/articles/[[page]].vue")
  },
  {
    name: "resources-collection-uid",
    path: "/resources/collection/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/collection/[uid].vue")
  },
  {
    name: "resources-collections-page",
    path: "/resources/collections/:page?",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/collections/[[page]].vue")
  },
  {
    name: "resources-events-page",
    path: "/resources/events/:page?",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/events/[[page]].vue")
  },
  {
    name: "resources-guide-uid",
    path: "/resources/guide/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/guide/[uid].vue")
  },
  {
    name: "resources-guides-page",
    path: "/resources/guides/:page?",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/guides/[[page]].vue")
  },
  {
    name: "resources",
    path: "/resources",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/index.vue")
  },
  {
    name: "resources-search",
    path: "/resources/search",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/search.vue")
  },
  {
    name: "resources-success-stories-page",
    path: "/resources/success-stories/:page?",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/success-stories/[[page]].vue")
  },
  {
    name: "resources-success-story-uid",
    path: "/resources/success-story/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/resources-center/resources-center-front/src/pages/resources/success-story/[uid].vue")
  },
  {
    name: "preview-page",
    path: "/resources/preview",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/preview.vue")
  },
  {
    name: "redirect_or_404",
    path: "/:pathMatch([^_].*)*",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/redirect.vue")
  }
]